.mainwrapper{
    width:100vw;
    height: 100vh;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-repeat:no-repeat;
    background-image: url("../images/mainbg.png");
    background-size: cover;
    position: relative;
    overflow: hidden;
}
  
  .subwrapper{
    width:100%;
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    padding:10px;
    
  }

  .body{
    display: flex;
    gap: 10px;
    width: 100%;
    height:100%;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .banner{
    position: absolute;
    width: 100%;
    height: 50px;
    left: 0;
    object-fit:cover;
    z-index: 1;
    top: 0%;
  }

  .titlewrapper{
    display: flex;
    flex-direction:row;
    align-items: center;
    justify-content: center;
  }

  .titleimg{
    content: url("../images/Section3/title.png");
    object-fit: contain;
  }

  .bg{
    object-fit: contain;
    height: 100%;
    position: absolute;
    content: url("../images/Section1/bg.png");
  }

  .text{
    margin: 0;
    font-family: var(--font-bahiana);
    color: white;
    background-color: black;
    padding: 10px;
    box-sizing: border-box;
  }


  @media screen and (max-width:500px) {

    .mainwrapper{
        max-height:350px;
        min-height:300px;
    }


    .titlewrapper{
        /* height: 30%; */
        gap: 5;
        align-items: center;
    }

    .titleimg{
        width: 120px;
    }

    .bg{
        filter: blur(10px);
    }

    .text{
        font-size: 30px;
    }

  }

  @media screen and (min-width:501px) and (max-width:1023px) {

    .mainwrapper{
        max-height: 450px;
        min-height:400px;
    }

    .titlewrapper{
        /* height: 30%; */
        gap: 5;
        align-items: center;
    }

    .titleimg{
        width: 150px;
    }

    .text{
        font-size: 40px;
    }

    .bg{
        filter: blur(10px);
    }

  }

  @media screen and (min-width:1024px) and (max-width:1920px) {

    .mainwrapper{
        max-height: 950px;
        min-height: 900px;
    }

    .titleimg{
        width: 400px;
    }

    .text{
        font-size: 70px;
    }

    .bg{
        filter: blur(10px);
    }

  }

  @media screen and (min-width:1921px){

    .mainwrapper{
        max-height: 1250px;
        min-height: 1200px;
    }

    .subwrapper{
        max-width: 2200px;
    }

    .titleimg{
        width: 500px;
    }

    .text{
        font-size: 100px;
    }

    .bg{
        filter: blur(10px);
    }

  }

