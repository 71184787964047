.mainwrapper{
    width:100vw;
    height: 100vh;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-repeat:no-repeat;
    background-image: url("../images/mainbg.png");
    background-size: cover;
    position: relative;
    overflow: hidden;
}
  
  .subwrapper{
    width:100%;
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    padding:10px;
    
  }

  .socialWrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .socialicons{
    object-fit: contain;
    height: auto;
  }

  .body{
    display: flex;
    gap: 10px;
    width: 100%;
    height:100%;
    box-sizing: border-box;
    position: relative;
  }

  .textwrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
  }

  .caHeading,.ca{
    margin: 0;
    color: white;
    font-family: var(--font-bahiana);
    padding: 10px;
  }


  .titleimg{
    content: url("../images/Section1/text.png");
    object-fit: contain;
  }

  .imgwrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    height: 100%;
  }

  .cawrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: black;
    border-radius: 100px;
  }

  .image{
    height: 100%;
    object-fit: cover;
    content: url("../images/Section1/image.png");
    border-color: black;
  }

  .bg{
    object-fit: contain;
    position: absolute;
    content: url("../images/Section1/bg.png");
  }

  .subtitle{
    font-family: var(--font-bahiana);
    color: white;
    margin: 0px;
  }

  @media screen and (max-width:500px) {

    .mainwrapper{
        max-height: 600px;
        min-height: 550px;
    }

    .body{
        padding: 50px;
        flex-direction: column;
    }

    .textwrapper{
        width: 100%;
        gap: 5;
        align-items:center;
    }

    .titleimg{
        width: 250px;
    }

    .subtitle{
        font-size: 30px;
    }

    .cawrapper{
        padding: 5px;
        /* width: 90%; */
    }

    .caHeading,.ca{
        font-size: 20px;
    }

    .copyicon{
        width: 20px;
    }

    .imgwrapper{
        width: 100%;
    }
    
    .image{
        width: 300px;
        border-width: 10px;
        height: 100%;
    }

    .bg{
        filter: blur(10px);
    }

    .socialWrapper{
        gap: 20px;
    }

    .socialicons{
        width: 30px;
        border-radius: 100px;
    }

    .banner{
        top:92%
    }


  }

  @media screen and (min-width:501px) and (max-width:1023px) {

    .mainwrapper{
        max-height: 800px;
        min-height: 750px;
    }

    .subwrapper{
        max-width: 2200px;
        flex-direction: column;
    }

    .body{
        padding: 100px;
        flex-direction: column;
    }

    .textwrapper{
        width: 100%;
        gap: 5;
        align-items:center;
    }

    .titleimg{
        width: 350px;
    }

    .subtitle{
        font-size: 40px;
    }

    .cawrapper{
        padding: 5px;
        /* width: 90%; */
    }

    .caHeading,.ca{
        font-size: 20px;
    }

    .copyicon{
        width: 20px;
    }

    .imgwrapper{
        width: 100%;
    }
    
    .image{
        width: 500px;
        border-width: 10px;
    }

    .bg{
        height: 100%;
        filter: blur(10px);
    }

    .socialWrapper{
        gap: 30px;
    }

    .socialicons{
        width: 50px;
        border-radius: 100px;
    }

    .banner{
        top:92%
    }


  }

  @media screen and (min-width:1024px) and (max-width:1920px) {

    .mainwrapper{
        max-height: 1080px;
        min-height: 1000px;
    }

    .subwrapper{
        max-width: 2200px;
        flex-direction: column;
    }

    .body{
        padding: 100px;
        flex-direction: column;
    }

    .textwrapper{
        width: 100%;
        gap: 5;
        align-items:center;
    }

    .titleimg{
        width: 450px;
    }

    .subtitle{
        font-size: 40px;
    }

    .cawrapper{
        width: 50%;
        gap: 10px;
        padding: 10px;
    }

    .caHeading,.ca{
        font-size: 30px;
    }

    .copyicon{
        width: 30px;
    }

    .imgwrapper{
        width: 100%;
    }
    
    .image{
        width: 700px;
        border-width: 10px;
    }

    .bg{
        filter: blur(10px);
    }

    .socialWrapper{
        gap: 30px;
    }

    .socialicons{
        width: 50px;
        border-radius: 100px;
    }

    .banner{
        top:92%
    }


  }

  @media screen and (min-width:1921px){

    .mainwrapper{
        max-height: 1080px;
        min-height: 1000px;
    }

    .subwrapper{
        max-width: 2200px;
        flex-direction: row;
    }

    .body{
        padding: 100px;
        flex-direction: row;
    }
    
    .socialWrapper{
        gap: 60px;
    }

    .socialicons{
        width: 75px;
        border-radius: 100px;
    }

    .textwrapper{
        width: 60%;
        gap: 5;
        align-items: center;
    }

    .titleimg{
        width: 500px;
    }

    .subtitle{
        font-size: 50px;
    }

    .cawrapper{
        padding: 10px;
        gap: 10px;
    }

    .caHeading,.ca{
        font-size: 50px;
    }

    .copyicon{
        width: 40px;
    }

    .imgwrapper{
        width: 60%;
    }
    
    .image{
        width: 800px;
        border-width: 10px;
    }

    .bg{
        filter: blur(10px);
    }

    .banner{
        top:92%
    }

  }

